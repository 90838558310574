import React from 'react';

function CommercialTwo() {
    return(
        <div>
            <div className="commercial__header">
                <h1 className="kur">Kur .!.</h1>
                <p>lorem aksjdas,dbasoid asdas'idla sdasdasd </p>
            </div>
        </div>
    )
}

export default CommercialTwo;
